import React, { useState, useEffect } from 'react';
import { Footer, Header } from "./headerFooter";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";

const Register = () => {
  // State to handle input values
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Call the /register endpoint with the attributes needed
      const url = `${process.env.REACT_APP_HOST}/api/register`
      console.log(`Attempting to call: ${url}`); // Debugging URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
          email,
          specialty
        }),
      });

      const data = await response.json();

      // Check if the response is OK
      if (response.ok) {
        setSuccess(data.message || "Επιτυχής εγγραφή");
        setError('');
      } else {
        setError(data.message || "Προσπαθήστε ξανά");
        setSuccess('');
      }

    } catch (error) {
      // Handle errors (e.g., wrong credentials)
      setError('Register failed, please try again');
      setSuccess('');
    }
  };

  const isFormValid = username !== '' && password !== '' && email !== '' && specialty !== '';
  
  return (
    <div id="root">
      <Header />
      <main className='center-container'>
        <Container>
          {error && <Alert key="danger" variant="danger">{error}</Alert>}

          {/* Success message */}
          {success ? (
            <div className="result">
             <Alert key="success" variant="success">{success}</Alert>
             <Button href="/" variant="info">Επιστροφή στην οθόνη εισόδου</Button>
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="registerForm.username">
                <Form.Label className="custom-label">Όνομα χρήστη:</Form.Label>
                <Form.Control className="custom-form" value={username} onChange={(e) => setUsername(e.target.value)} required/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="registerForm.password">
                <Form.Label className="custom-label">Κωδικός πρόσβασης</Form.Label>
                <Form.Control className="custom-form" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="registerForm.email">
                <Form.Label className="custom-label">e-mail</Form.Label>
                <Form.Control className="custom-form" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="registerForm.specialty">
                <Form.Label className="custom-label">Ειδικότητα</Form.Label>
                <Form.Control className="custom-form" type="specialty" value={specialty} onChange={(e) => setSpecialty(e.target.value)} required/>
              </Form.Group>
              <Button variant="danger" type="submit" disabled={!isFormValid}>Ολοκλήρωση εγγραφής</Button>
            </Form>
          )}
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default Register;