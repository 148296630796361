import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './App.css'; // Import the CSS file to apply styles
import { Footer, Header } from "./headerFooter";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";


const Login = () => {
  // State to handle input values
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    
    navigate('/', { replace: true });
  }, [navigate]);
  
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Call the /login endpoint with the username and password using fetch
      const url = `${process.env.REACT_APP_HOST}/api/login`
      console.log(`Attempting to call: ${url}`); // Debugging URL
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Login failed');
      }

      // Extract token from response
      const data = await response.json();
      const token = data.token;
      const refreshToken = data.refresh_token;

      // Save the token in localStorage
      localStorage.setItem('authToken', token);
      localStorage.setItem('refreshToken', refreshToken);

      // Redirect to another page or update the state to show authenticated content
      navigate('/main', { replace: true });
      // window.location.href = '/dashboard'; // Example: redirect to another page
    } catch (error) {
      // Handle errors (e.g., wrong credentials)
      setError('Invalid username or password');
    }
  };

  const isFormValid = username !== '' && password !== '';

  return (
    <div id="root">
        <Header />
          <main className='center-container'>
            <Container>
              {error && <p className="error-message">{error}</p>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="loginForm.username">
                  <Form.Label className="custom-label">Όνομα χρήστη</Form.Label>
                  <Form.Control className="custom-form" value={username} onChange={(e) => setUsername(e.target.value)} required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="loginForm.password">
                  <Form.Label className="custom-label">Κωδικός πρόσβασης</Form.Label>
                  <Form.Control className="custom-form" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                </Form.Group>
                <Row>
                <Col>
                  <Button variant="danger" type="submit" disabled={!isFormValid}>Είσοδος</Button>
                </Col>
                {/* <Col className="text-end">
                  <Link to="/register">
                    <Button variant="outline-danger">Εγγραφή</Button>
                  </Link>
                </Col> */}
              </Row>
              </Form>
            </Container>
          </main>
        <Footer />
    </div>

  );
};

export default Login;