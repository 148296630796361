import React, { useState } from 'react';
import './App.css'; // Import CSS file for styling
import { useNavigate } from 'react-router-dom';
import { PROKIRIKSI_OPTIONS } from './constants';
import { Header, Footer } from './headerFooter';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";

const token = localStorage.getItem('authToken');

function RankingPersonalDetails() {
    const navigate = useNavigate();
    // State variables to hold form data
    const [formData, setFormData] = useState({
        table_type: '',
        last_name: '',
        first_name: '',
        father_name: '',
        id_card_number: ''
    });

    // State variable to hold API results
    const [results, setResults] = useState(null);

    // Handler for form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Make POST request to your FastAPI endpoint
            const url = `${process.env.REACT_APP_HOST}/api/personal-ranking`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formData)
            });

            const responseData = await response.json();
            // Update results state with response data
            setResults(responseData.results);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Handler for form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLogout = () => {
        // Clear the authToken from localStorage
        localStorage.removeItem('authToken');

        // Redirect to the login page
        navigate('/', { replace: true });
    }
    
    const isFormValid = formData.last_name !== '' && formData.table_type !== '';
    
    return (
        <div id="root">
            <Header handleLogout={handleLogout}/>
            <main className='main-container'>
                <Container className='custom-container'>
                    <Form.Group className="mb-3 pt-3">
                        <Form.Label className='custom-label'>Προκύρηξη διαγωνισμού:</Form.Label>
                        <Form.Select className="custom-form" name="table_type" value={formData.table_type} onChange={handleChange} required>
                            <option value="">Επιλέξτε προκύρηξη διαγωνισμού</option>
                            {PROKIRIKSI_OPTIONS.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option> ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className='custom-label'>Επώνυμο:</Form.Label>
                        <Form.Control className="custom-form" type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className='custom-label'>Όνομα:</Form.Label>
                        <Form.Control className="custom-form" type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className='custom-label'>Πατρώνυμο:</Form.Label>
                        <Form.Control className="custom-form" type="text" name="father_name" value={formData.father_name} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className='custom-label'>Α.Δ.Τ.:</Form.Label>
                        <Form.Control className="custom-form" type="text" name="id_card_number" value={formData.id_card_number} onChange={handleChange} required />
                    </Form.Group>
                    <Button variant="secondary" type="Εύρεση" onClick={handleSubmit} disabled={!isFormValid}>Εύρεση</Button>
                    {results && (
                            <>
                                <h3 className='my-3'>Αποτελέσματα</h3>
                                {results.map((result, index) => (
                                    <Card className='custom-card'>
                                        <Card.Body>
                                            <ListGroup className="list-group-flush">
                                                <ListGroup.Item>Επώνυμο: {result.last_name}</ListGroup.Item>
                                                <ListGroup.Item>Όνομα: {result.first_name}</ListGroup.Item>
                                                <ListGroup.Item>Πατρώνυμο: {result.father_name}</ListGroup.Item>
                                                <ListGroup.Item>Θέση πίνακα: {result.ranking}</ListGroup.Item>
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </>
                            
            )}
                </Container>
            </main>           
            <Footer />

            
        </div>

    );
}

export default RankingPersonalDetails;
