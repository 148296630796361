import React, { useState, useEffect } from 'react';
import { Footer, Header } from './headerFooter';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";

function RankingPerState() {
    const [data, setData] = useState(null);
    const [uniqueDates, setUniqueDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [uniqueLocations, setUniqueLocations] = useState(null);
    const [yearRange, setYearRange] = useState(''); // New state for year range
    const [specialty, setSpecialty] = useState(''); // New state for specialty
    const [eidikiAgogi, setEidikiAgogi] = useState('false'); // New state for eidiki_agogi
    const [specialtyOptions, setSpecialtyOptions] = useState([]); // Options for specialty dropdown
    const navigate = useNavigate();
    const token = localStorage.getItem('authToken');

    // Function to handle logout
    const handleLogout = () => {
        // Clear the authToken from localStorage
        localStorage.removeItem('authToken');

        // Redirect to the login page
        navigate('/', { replace: true });
    }
    
    // Fetch specialties based on the selected year_range
    useEffect(() => {
        if (yearRange) { // Fetch only if yearRange is selected
            const url = `${process.env.REACT_APP_HOST}/api/specialty-types?year_range=${yearRange}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(response => response.json())
                .then(data => setSpecialtyOptions(data.results))
                .catch(error => console.error('Error fetching specialties:', error));
        } else {
            setSpecialtyOptions([]); // Clear specialties when yearRange is not selected
        }
    }, [yearRange]); // Trigger fetch when yearRange changes

    // Handle form submission to fetch data based on the selected filters
    const handleSubmit = () => {
        const url = `${process.env.REACT_APP_HOST}/api/states-min-max`;
        const requestBody = {
            year_range: yearRange,
            specialty: specialty,
            eidiki_agogi: eidikiAgogi === 'true', // Send boolean value
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                setData(data.results);
                const dates = new Set();
                const locations = new Set();
                data.results.forEach(locationData => {
                    Object.keys(locationData).forEach(location => {
                        locations.add(location);
                        Object.keys(locationData[location]).forEach(date => {
                            dates.add(date);
                        });
                    });
                });
                setUniqueDates(Array.from(dates).sort());
                setUniqueLocations(Array.from(locations).sort());
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        if (selectedDate === "all") {
            setSelectedDates(uniqueDates);
        } else {
            setSelectedDates([selectedDate]);
        }
    };

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const filterDataByDates = (data, dates) => {
        return data.filter(locationData => {
            if (!selectedLocation) return true;
            return Object.keys(locationData)[0] === selectedLocation;
        }).map(locationData => {
            const filteredInfo = {};
            Object.keys(locationData[Object.keys(locationData)[0]]).forEach(date => {
                if (dates.includes(date)) {
                    filteredInfo[date] = locationData[Object.keys(locationData)[0]][date];
                }
            });
            return { [Object.keys(locationData)[0]]: filteredInfo };
        });
    };

    const isFormValid = yearRange !== '' && specialty !== '';
    return (
        <div id="root">
            <Header handleLogout={handleLogout}/>
            {/* <div className='center-container'> */}
                <main className='main-container'>
                    <Container className='custom-container'>
                        <Form.Group className="mb-3 pt-3" controlId="yearSelect.range">
                            <Form.Label className='custom-label'>Έτος:</Form.Label>
                            <Form.Select className="custom-form" value={yearRange} onChange={(e) => setYearRange(e.target.value)}>
                                <option>Επιλέξτε...</option>
                                <option value="2022_2023">2022-2023</option>
                                <option value="2023_2024">2023-2024</option>
                                <option value="2024_2025">2024-2025</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="yearSelect.specialty">
                            <Form.Label className='custom-label'>Ειδικότητα:</Form.Label>
                            <Form.Select className="custom-form" value={specialty} onChange={(e) => setSpecialty(e.target.value)} disabled={!yearRange}>
                                <option>Επιλέξτε...</option>
                                {specialtyOptions.length > 0 ? (
                                    specialtyOptions
                                    .slice() // Create a shallow copy of the array to avoid mutating the original
                                    .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                    .map((spec) => (
                                        <option key={spec} value={spec}>
                                            {spec}
                                        </option>
                                    ))
                                ) : (
                                    <option>No specialties available</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="yearSelect.eidiki">
                            <Form.Label className='custom-label'>Ειδική Αγωγή:</Form.Label>
                            <Form.Select className="custom-form" value={eidikiAgogi} onChange={(e) => setEidikiAgogi(e.target.value)}>
                                <option value="false">Όχι</option>
                                <option value="true">Ναι</option>
                            </Form.Select>
                        </Form.Group>
                        <Button variant="secondary" type="submit" onClick={handleSubmit} disabled={!isFormValid}>Υποβολή</Button>
                        
                        {data ? (
                            <div>
                                <Form>
                                    <Form.Group className="mb-3 pt-3" controlId="yearSelect.location">
                                        <Form.Label className='custom-label'>Διεύθυνση Εκπαίδευσης:</Form.Label>
                                        <Form.Select className="custom-form" onChange={handleLocationChange}>
                                            <option value="">Επιλέξτε...</option>
                                            {uniqueLocations.map(location => (
                                                <option key={location} value={location}>{location}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="yearSelect.date">
                                        <Form.Label className='custom-label'>Ημερομηνία φάσης προσλήψεων:</Form.Label>
                                        <Form.Select className="custom-form" onChange={handleDateChange}>
                                            <option value="">Επιλέξτε...</option>
                                            <option value="all">Όλες οι ημερομηνίες</option>
                                            {uniqueDates.map(date => (
                                                <option key={date} value={date}>{date}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>

                                
                                {selectedDates.length > 0 && selectedLocation && (
                                    <div className="data-container">
                                        {filterDataByDates(data, selectedDates).map(locationData => (
                                                <>
                                                    {Object.entries(locationData[Object.keys(locationData)[0]]).map(([date, info]) => (
                                                        <Card className='custom-card' key={Object.keys(locationData)[0]}>
                                                            <Card.Body>
                                                                <Card.Title>{Object.keys(locationData)[0]}</Card.Title>
                                                                <ListGroup className="list-group-flush">
                                                                    <ListGroup.Item>Ημερομηνία: {date}</ListGroup.Item>
                                                                    <ListGroup.Item>Πρώτος στον πίνακα: {info.table_min}</ListGroup.Item>
                                                                    <ListGroup.Item>Τελευταίος στον πίνακα: {info.table_max}</ListGroup.Item>
                                                                    <ListGroup.Item>Συνολικές προσλήψεις: {info.total}</ListGroup.Item>
                                                                </ListGroup>
                                                            </Card.Body>
                                                        </Card>
                                                    ))}
                                                </>
                                                

                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p></p>
                        )}
                    </Container>
                </main>
            {/* </div> */}
            
            <Footer />
        </div>
    );
}

export default RankingPerState;
