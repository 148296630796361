// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RankingPerState from './rankingPerState'; // Import the Component1
import RankingPerScore from "./rankingPerScore";
import RankingPersonalDetails from "./rankingPersonalDetails";
import MainPage from "./mainPage";
import AppointmentDate from "./restComponents";
import RankingAboveMe from "./peopleAboveMe";
import RentPrices from "./rentPrices";
import Login from './loginPage';
import Register from './registerPage';
import './custom.scss';



const refreshAuthToken = async() => {
    try {
        const token = localStorage.getItem('authToken');
        const url = `${process.env.REACT_APP_HOST}/api/refresh`
        if (!token) return;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ refresh_token: localStorage.getItem('refreshToken') })
          });

          const data = await response.json();
          localStorage.setItem('authToken', data.token);
    } catch (error) {
        console.error("Error refreshing token", error);
    }
};

const useTokenRefresh = () => {
    useEffect(() => {
        const intervalId = setInterval(() => {
            refreshAuthToken();
        }, 30 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, []);
};

function App() {
    useTokenRefresh();
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Login />} /> {/* Route for page 1 */}
                <Route exact path="/register" element={<Register />} /> {/* Route for page 1 */}
                <Route exact path="/main" element={<MainPage />} /> {/* Route for page 1 */}
                <Route exact path="/ranking-per-state" element={<RankingPerState />} /> {/* Route for page 1 */}
                <Route exact path="/appointment-date" element={<AppointmentDate />} /> {/* Route for page 1 */}
                <Route exact path="/ranking-per-score" element={<RankingPerScore />} /> {/* Route for page 1 */}
                <Route exact path="/ranking-personal-details" element={<RankingPersonalDetails />} /> {/* Route for page 1 */}
                <Route exact path="/ranking-above-me" element={<RankingAboveMe />} />
                <Route exact path="/rent-prices" element={<RentPrices />} />
            </Routes>
        </Router>
    );
}

export default App;