import React, { useState, useEffect } from 'react';
import { Footer, Header } from './headerFooter';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";
import { useNavigate } from 'react-router-dom';

function RankingPerScore() {
    const [data, setData] = useState(null);
    const [uniqueDates, setUniqueDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [uniqueLocations, setUniqueLocations] = useState(null);
    const [yearRange, setYearRange] = useState(''); // New state for year range
    const [specialty, setSpecialty] = useState(''); // New state for specialty
    const [eidikiAgogi, setEidikiAgogi] = useState('false'); // New state for eidiki_agogi
    const [specialtyOptions, setSpecialtyOptions] = useState([]); // Options for specialty dropdown
    const [positionInTable, setPositionInTable] = useState(''); // New state for position in table input
    const [dateOptions, setDateOptions] = useState([]); // New state for fetched date options
    const [selectedValue, setSelectedValue] = useState(''); // New state for the input field value
    const [submitted, setSubmitted] = useState(false); // To check if the form was submitted
    const [selectedDate, setSelectedDate] = useState('');
    const navigate = useNavigate();
    const token = localStorage.getItem('authToken');

    // Fetch specialties based on the selected year_range
    useEffect(() => {
        if (yearRange) { // Fetch only if yearRange is selected
            const url = `${process.env.REACT_APP_HOST}/api/specialty-types?year_range=${yearRange}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(response => response.json())
                .then(data => setSpecialtyOptions(data.results))
                .catch(error => console.error('Error fetching specialties:', error));
        } else {
            setSpecialtyOptions([]); // Clear specialties when yearRange is not selected
        }
    }, [yearRange]);

    // Fetch dates from the /api/dates endpoint based on yearRange
    useEffect(() => {
        if (yearRange && specialty) {
            const url = `${process.env.REACT_APP_HOST}/api/dates?year_range=${yearRange}&specialty=${specialty}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log("API Response:", data);
                    console.log("Dates is array", Array.isArray(data))
                    const sortedDates = data.sort((a, b) => new Date(a) - new Date(b));
                    setDateOptions(sortedDates || []);
                })
                .catch(error => console.error('Error fetching dates:', error));
        } else {
            setDateOptions([]);
        }
    }, [yearRange, specialty, token]);

    // Handle form submission to fetch data based on the selected filters
    const handleSubmit = () => {
        const url = `${process.env.REACT_APP_HOST}/api/possible_placement`;
        const requestBody = {
            year_range: yearRange,
            specialty: specialty,
            eidiki_agogi: eidikiAgogi === 'true', // Send boolean value
            ranking: positionInTable,  // New field in request body
            phase: selectedDate
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                setData(data.results);
                setSubmitted(true);
                const dates = new Set();
                const locations = new Set();
                data.results.forEach(locationData => {
                    Object.keys(locationData).forEach(location => {
                        locations.add(location);
                        Object.keys(locationData[location]).forEach(date => {
                            dates.add(date);
                        });
                    });
                });
                setUniqueDates(Array.from(dates).sort());
                setUniqueLocations(Array.from(locations).sort());
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const handleValueChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handlePositionChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) { // Validate that only numbers are allowed
            setPositionInTable(value);
        }
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleLogout = () => {
        // Clear the authToken from localStorage
        localStorage.removeItem('authToken');

        // Redirect to the login page
        navigate('/', { replace: true });
    }

    const isFormValid = yearRange !== '' && specialty !== '' && positionInTable !== '';

    return (
        <div id="root">
            <Header handleLogout={handleLogout}/>
            <main className='main-container'>
                <Container className='custom-container'>
                    <Form.Group className="mb-3 pt-3" controlId="yearSelect.range">
                        <Form.Label className='custom-label'>Έτος:</Form.Label>
                        <Form.Select className="custom-form" value={yearRange} onChange={(e) => setYearRange(e.target.value)}>
                            <option>Επιλέξτε...</option>
                            <option value="2022_2023">2022-2023</option>
                            <option value="2023_2024">2023-2024</option>
                            <option value="2024_2025">2024-2025</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="yearSelect.specialty">
                        <Form.Label className='custom-label'>Ειδικότητα:</Form.Label>
                        <Form.Select className="custom-form" value={specialty} onChange={(e) => setSpecialty(e.target.value)} disabled={!yearRange}>
                        <option value="">Επιλέξτε...</option>
                            {specialtyOptions.length > 0 ? (
                                specialtyOptions
                                .slice() // Create a shallow copy of the array to avoid mutating the original
                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                .map((spec) => (
                                    <option key={spec} value={spec}>
                                        {spec}
                                    </option>
                                ))
                            ) : (
                                <option value="">No specialties available</option>
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="yearSelect.position">
                        <Form.Label className='custom-label'>Θέση πίνακα:</Form.Label>
                        <Form.Control className="custom-form" type="text" placeholder="Εισάγετε την θέση σας στον πίνακα" value={positionInTable} onChange={handlePositionChange}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="yearSelect.date">
                        <Form.Label className='custom-label'>Ημερομηνία φάσης προσλήψεων:</Form.Label>
                        <Form.Select className="custom-form" onChange={handleDateChange} value={selectedDate}>
                            <option value="">Επιλέξτε...</option>
                            {dateOptions.length > 0 ? (
                                dateOptions.map(date => (
                                    <option key={date} value={date}>{date}</option>
                                ))
                            ) : (
                                <option value="">No dates available</option> // Handle empty dates case
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="yearSelect.eidiki">
                        <Form.Label className='custom-label'>Ειδική Αγωγή:</Form.Label>
                        <Form.Select className="custom-form" value={eidikiAgogi} onChange={(e) => setEidikiAgogi(e.target.value)}>
                            <option value="false">Όχι</option>
                            <option value="true">Ναι</option>
                        </Form.Select>
                    </Form.Group>
                    <Button variant="secondary" type="submit" onClick={handleSubmit} disabled={!isFormValid}>Υποβολή</Button>
                    
                    
                    {submitted && data ? (
                        <div className="results">
                            {data.length > 0 ? (
                                data.map((locationData2, index) => (
                                    <div>
                                    {Object.entries(locationData2).map(([location, info]) => (
                                        <Card className='custom-card' key={Object.keys(locationData2)[0]}>
                                            <Card.Body>
                                                <Card.Title>{location}</Card.Title>
                                                <ListGroup className="list-group-flush">
                                                <ListGroup.Item>Πρώτος στον πίνακα: {info.table_min}</ListGroup.Item>
                                                <ListGroup.Item>Τελευταίος στον πίνακα: {info.table_max}</ListGroup.Item>
                                                <ListGroup.Item>Συνολικές προσλήψεις: {info.total}</ListGroup.Item>
                                            </ListGroup> 
                                            </Card.Body>
                                        </Card>
                                    ))}
                                    </div>
                            ))
                        ) : (
                            <p>Δεν υπάρχουν αποτελέσματα.</p>  
                        )}
                        </div>
                    ) : (
                <p></p>
            )}
                </Container>
            </main>
            <Footer />
        </div>
    );
}

export default RankingPerScore;
