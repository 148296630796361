import React from "react";
import { Container, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


// Footer Component
const Footer = () => {
    return (
      <footer className="bg-success footer-custom py-3">
        <Container>
          <div className="text-center mt-1">
            <p>&copy; {new Date().getFullYear()} teachers-app.com. All Rights Reserved.</p>
          </div>
        </Container>
      </footer>
    );
  };
  
// Header Component
const Header = ({ handleLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const pageTitles = {
    "/": "Είσοδος",
    "/main": "Χρήσιμα εργαλεία για αναπληρωτές",
    "/ranking-per-state": "Μόρια ανά περιοχή ανά φάση",
    "/ranking-per-score": "Έλεγχος περιοχων βάσει θέσης πίνακα",
    "/ranking-personal-details": "Εύρεση θέσης πίνακα",
    "/appointment-date": "Πρόσληψη βάσει σειράς πίνακα",
    "/ranking-above-me": "Άτομα πριν την κατάταξή μου",
    "/rent-prices": "Τιμές ενοικίου",
    "/register": "Εγγραφή χρήστη"
  };

  const handleButtonClick = () => {
    if (location.pathname === '/register') {
      navigate('/');
    } else {
      handleLogout();
    }
  };

  const handleMainPageClick = () => {
    navigate('/main');
  };

  const currentTitle = pageTitles[location.pathname];

  return (
    <header className="bg-success header-custom py-3">
      <Container className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center" style={{ minWidth: "128px" }}>
          {location.pathname !== '/main' && location.pathname !== '/' && location.pathname !== '/register' && (
            <Button onClick={handleMainPageClick} variant="danger" className="me-2">
              Αρχική Σελίδα
            </Button>
          )}
        </div>
        <div class="d-flex justify-content-center flex-grow-1">
          <h3 class="text-info-emphasis">{currentTitle}</h3>
        </div>
        <div className="d-flex align-items-center" style={{ minWidth: "128px" }}>
          {location.pathname !== '/' && (
            <Button onClick={handleButtonClick} variant="outline-danger">
              {location.pathname === '/register' ? 'Επιστροφή στην αρχική' : 'Αποσύνδεση'}
            </Button>
          )}
        </div>
      </Container>
    </header>
  );
};

// Export Components
export { Footer, Header };