import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer, Header } from './headerFooter';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";

function AppointmentDate() {
    const [minDate, setMinDate] = useState(null);
    const [ranking, setRanking] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [yearRange, setYearRange] = useState(''); // New state for year range
    const [specialty, setSpecialty] = useState(''); // New state for specialty
    const [specialtyOptions, setSpecialtyOptions] = useState([]); // Options for specialty dropdown
    const token = localStorage.getItem('authToken');

    useEffect(() => {
        if (yearRange) { // Fetch only if yearRange is selected
            const url = `${process.env.REACT_APP_HOST}/api/specialty-types?year_range=${yearRange}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(response => response.json())
                .then(data => setSpecialtyOptions(data.results))
                .catch(error => console.error('Error fetching specialties:', error));
        } else {
            setSpecialtyOptions([]); // Clear specialties when yearRange is not selected
        }
    }, [yearRange]);


    
    const handleSubmit = () => {
        const url = `${process.env.REACT_APP_HOST}/api/insert-date`
        const requestBody = {
            ranking: ranking,
            year_range: yearRange,
            specialty: specialty

        };
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),

        })
            .then(response => response.json())
            .then(data => {
                if (data.results && Object.keys(data.results).length > 0) {
                    const { min_date } = data.results;
                    if (min_date === -1) {
                        setErrorMessage('No valid ranking of table');
                    } else {
                        setMinDate(min_date);
                        setShowResults(true);
                        setErrorMessage('');
                    }
                } else {
                    setErrorMessage('No results found');
                }
            })
            .catch(error => {
                console.error('Error submitting data:', error);
            });
    };

    const handleLogout = () => {
        // Clear the authToken from localStorage
        localStorage.removeItem('authToken');

        // Redirect to the login page
        navigate('/', { replace: true });
    };

    return (
        <div id="root">
            <Header handleLogout={handleLogout}/>
            <main className='main-container'>
                <Container className='custom-container'>
                <Form.Group className="mb-3 pt-3" controlId="yearSelect.range">
                        <Form.Label className='custom-label'>Έτος:</Form.Label>
                        <Form.Select className="custom-form" value={yearRange} onChange={(e) => setYearRange(e.target.value)}>
                            <option>Επιλέξτε...</option>
                            <option value="2022_2023">2022-2023</option>
                            <option value="2023_2024">2023-2024</option>
                            <option value="2024_2025">2024-2025</option>
                        </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="yearSelect.specialty">
                        <Form.Label className='custom-label'>Ειδικότητα:</Form.Label>
                        <Form.Select className="custom-form" value={specialty} onChange={(e) => setSpecialty(e.target.value)} disabled={!yearRange}>
                        <option value="">Επιλέξτε...</option>
                            {specialtyOptions.length > 0 ? (
                                specialtyOptions
                                .slice() // Create a shallow copy of the array to avoid mutating the original
                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                .map((spec) => (
                                    <option key={spec} value={spec}>
                                        {spec}
                                    </option>
                                ))
                            ) : (
                                <option value="">No specialties available</option>
                            )}
                        </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                        <Form.Label className='custom-label'>Θέση πίνακα:</Form.Label>
                        <Form.Control className="custom-form" type="text" placeholder="Εισάγετε την θέση σας στον πίνακα" name="id_card_number" value={ranking} onChange={(e) => setRanking(e.target.value)} />
                </Form.Group>
                <Button variant="secondary" onClick={handleSubmit}>Υποβολή</Button>
                
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {showResults && !errorMessage && (
                    <Card className='custom-card' key="results">
                    <Card.Body>
                        <Card.Title>Ημερομηνία πρόσληψης</Card.Title>
                        {minDate ? (
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>Έγινε πρόσληψη στις: {minDate}</ListGroup.Item>
                        </ListGroup>
                        ) : (
                            <p>Δεν υπάρχουν αποτελέσματα</p>
                        )}
                    </Card.Body>
                    </Card> )}
                
                </Container>
            </main>
            <Footer />
        </div>
    );
}

export default AppointmentDate;