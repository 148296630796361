import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { Footer, Header } from "./headerFooter";
import './custom.scss';

function MainPage() {
    const navigate = useNavigate();

    // Function to handle logout
    const handleLogout = () => {
        // Clear the authToken from localStorage
        localStorage.removeItem('authToken');

        // Redirect to the login page
        navigate('/', { replace: true });
    };

    return (
        <div id='root'>            
            <Header handleLogout={handleLogout} />
            <main className='main-container'>
                <Container>
                    <Accordion defaultActiveKey='0'>
                        <Accordion.Item eventKey='0'>
                            <Accordion.Header>
                                Μόρια ανά περιοχή ανά φάση
                            </Accordion.Header>
                            <Accordion.Body className='custom-accordion-body'>
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                <div>
                                    <Button variant="secondary" href="/ranking-per-state">Είσοδος</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header>
                                    Έλεγχος περιοχών βάσει θέσης πίνακα
                            </Accordion.Header>
                            <Accordion.Body className='custom-accordion-body'>
                                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                <div>
                                    <Button variant="secondary" href="/ranking-per-score">Είσοδος</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2'>
                            <Accordion.Header>
                                Εύρεση θέσης πίνακα
                            </Accordion.Header>
                            <Accordion.Body className='custom-accordion-body'>
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                <div>
                                    <Button variant="secondary" href="/ranking-personal-details">Είσοδος</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='3'>
                            <Accordion.Header>
                                Πρόσληψη βάσει σειράς πίνακα
                            </Accordion.Header>
                            <Accordion.Body className='custom-accordion-body'>
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                <div>
                                    <Button variant="secondary" href="/appointment-date">Είσοδος</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='4'>
                            <Accordion.Header>
                                Έλεγχος συνολικού αριθμού ανθρώπων που προηγούνται
                            </Accordion.Header>
                            <Accordion.Body className='custom-accordion-body'>
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                <div>
                                    <Button variant="secondary" href="/ranking-above-me">Είσοδος</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey='5'>
                            <Accordion.Header>
                                Τιμές ενοικίου
                            </Accordion.Header>
                            <Accordion.Body className='custom-accordion-body'>
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                <div>
                                    <Button variant="secondary" href="/rent-prices">Είσοδος</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item> */}
                    </Accordion>
                </Container>
            </main>
            <Footer />
        </div>
    );
}

export default MainPage;