import React, { useState, useEffect } from 'react';
import { Footer, Header } from './headerFooter';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.scss";

function RankingAboveMe() {
    const [data, setData] = useState(null);
    const [uniqueDates, setUniqueDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedValue, setSelectedValue] = useState(''); // State for user-entered value
    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [submitted, setSubmitted] = useState(false); // State to track if form submitted
    const [isValidInput, setIsValidInput] = useState(true);
    const [yearRange, setYearRange] = useState(''); // New state for year range
    const [specialty, setSpecialty] = useState(''); // New state for specialty
    const [specialtyOptions, setSpecialtyOptions] = useState([]); // Options for specialty dropdown
    const navigate = useNavigate();
    const token = localStorage.getItem('authToken');

    useEffect(() => {
        if (yearRange && specialty) {
            const url = `${process.env.REACT_APP_HOST}/api/places?year_range=${yearRange}&specialty=${specialty}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log('API Data:', data);
                    setData(data.results);
                    const locations = new Set();
                    data.results.forEach(location => {
                        locations.add(location);
                    });
                    setUniqueLocations(Array.from(locations).sort());
                    console.log(setUniqueLocations)
                })
                .catch(error => console.error('Error fetching data:', error));
        } else {
            setUniqueLocations([]); // Clear specialties when yearRange is not selected
        }
    }, [yearRange, specialty]);

     // Fetch specialties based on the selected year_range
     useEffect(() => {
        if (yearRange) { // Fetch only if yearRange is selected
            const url = `${process.env.REACT_APP_HOST}/api/specialty-types?year_range=${yearRange}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(response => response.json())
                .then(data => setSpecialtyOptions(data.results))
                .catch(error => console.error('Error fetching specialties:', error));
        } else {
            setSpecialtyOptions([]); // Clear specialties when yearRange is not selected
        }
    }, [yearRange]);

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const handleValueChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        // Regular expression to match only integers
        const isValid = /^\d+$/.test(value);
        setIsValidInput(isValid);
    };

    const handleSubmit = () => {
        // Send POST request with selected value and selected date
        if (selectedValue !== '' && selectedDate !== '') {
            const url = `${process.env.REACT_APP_HOST}/api/place-ranking`
            const requestBody = {
                ranking: selectedValue,
                place: selectedLocation,
                year_range: yearRange,
                specialty: specialty
            }
            console.log(requestBody)
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            })
                .then(response => response.json())
                .then(data => {
                    // Handle response data
                    console.log(data);
                    setData(data.results);
                    const dates2 = new Set();
                    data.results.forEach(dateData2 => {
                        Object.keys(dateData2).forEach(date2 => {
                            dates2.add(date2);
                        });
                    });
                    setSubmitted(true);
                })
                .catch(error => console.error('Error sending POST request:', error));
        }
    };

    const handleLogout = () => {
        // Clear the authToken from localStorage
        localStorage.removeItem('authToken');

        // Redirect to the login page
        navigate('/', { replace: true });
    };

    return (
        <div id="root">
            <Header handleLogout={handleLogout}/>
            <main className='main-container'>
                <Container className='custom-container'>
                    <Form.Group className="mb-3 pt-3" controlId="yearSelect.range">
                        <Form.Label className='custom-label'>Έτος:</Form.Label>
                        <Form.Select className="custom-form" value={yearRange} onChange={(e) => setYearRange(e.target.value)}>
                            <option>Επιλέξτε...</option>
                            <option value="2022_2023">2022-2023</option>
                            <option value="2023_2024">2023-2024</option>
                            <option value="2024_2025">2024-2025</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="yearSelect.specialty">
                        <Form.Label className='custom-label'>Ειδικότητα:</Form.Label>
                        <Form.Select className="custom-form" value={specialty} onChange={(e) => setSpecialty(e.target.value)} disabled={!yearRange}>
                            <option>Επιλέξτε...</option>
                            {specialtyOptions.length > 0 ? (
                                specialtyOptions
                                .slice() // Shallow copy
                                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                .map((spec) => (
                                    <option key={spec} value={spec}>
                                        {spec}
                                    </option>
                                ))
                            ) : (
                                <option>No specialties available</option>
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className='custom-label'>Θέση πίνακα:</Form.Label>
                        <Form.Control className="custom-form" type="text" placeholder="Εισάγετε την θέση σας στον πίνακα" value={selectedValue} onChange={handleValueChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="yearSelect.location">
                        <Form.Label className='custom-label'>Τοποθεσία προτίμησης:</Form.Label>
                        <Form.Select className="custom-form" onChange={handleLocationChange}>
                            <option>Επιλέξτε τοποθεσία προτίμησης...</option>
                            {uniqueLocations.map(location => (
                                        <option key={location} value={location}>{location}</option>
                                    ))}
                        </Form.Select>
                    </Form.Group>
                    <Button variant="secondary" type="submit" onClick={handleSubmit}>Υποβολή</Button>

                    {submitted && data && (
                        <div className="results">
                            {data ? (
                                data.map((locationData2, index) => (
                                    <div key={index} className="result">
                                        {Object.entries(locationData2).map(([location, info]) => (
                                            <Card className='custom-card' key={location}>
                                                <Card.Body>
                                                    <Card.Title>{location}</Card.Title>
                                                    <ListGroup className="list-group-flush">
                                                        <ListGroup.Item>Άτομα πριν από μένα: {info.total_smaller}</ListGroup.Item>
                                                        <ListGroup.Item>Συνολικές προσλήψεις: {info.total}</ListGroup.Item>
                                                    </ListGroup>
                                                </Card.Body>

                                            </Card>

                                    
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <p>Φόρτωση...</p>
                            )}
                        </div>
                    )}
                </Container>
            </main>
            <Footer />
        </div>
    );
}

export default RankingAboveMe;
