// Λίστα με τις προκυρίξεις των διορισμών
export const PROKIRIKSI_OPTIONS = [
    { value: "1ge_2019", label: "1ΓΕ_2019" },
    { value: "1ge_2023", label: "1ΓΕ_2023" },
    { value: "2ea_2019", label: "2ΕΑ_2019" },
    { value: "2ea_2022", label: "2ΕΑ_2022" },
    { value: "2ge_2019", label: "2ΓΕ_2019" },
    { value: "2ge_2023", label: "2ΓΕ_2023" },
    { value: "3ea_2019", label: "3ΕΑ_2019" },
    { value: "3ea_2022", label: "3ΕΑ_2022" }
]